import React from 'react'

function Loading() {
  return (
    <div>
    <div style={{position:'fixed',width:'100vw',height:'100vh' , zIndex:'8',backgroundColor:'#00000059'}}  className="d-flex justify-content-center align-items-center">

            <div class="spinner-grow text-secondary " style={{width:'4rem',height:'4rem'}} role="status">
            <span class="visually-hidden">Loading...</span>
            </div>

    </div>
    </div>
  )
}

export default Loading