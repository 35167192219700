import React,{useEffect,useState} from 'react'
import {homeText,inOutText , commercialText, disInfectionText, springText} from '../Text/TextInOut.js'
import {
  hti,springMain,moveMain,infectionMain,commercialMain,
  bedroom,bathroom,kitchen,other,teaIcon,
  bathroomIcon,office, officeIcon, 
} from '../assets/images/Index.js'


function Service(props) {

  const [desc , setDesc] = useState('')  
  const [blockIText , setblockIText] = useState('')  
  const [blockIIText , setblockIIText] = useState('') 
  const [blockIIIText , setblockIIIText] = useState('')  
  const [blockIVText , setblockIVText] = useState('')  
  const [notIncluded , setNotIncluded] = useState('') 

  // images states
  const [mainImage , setMainImage] = useState(null)  
  const [vectori , setVectori] = useState(null)  
  const [vectorii , setVectorii] = useState(null)  
  const [vectoriii , setVectoriii] = useState(null)  
  const [vectoriv , setVectoriv] = useState(null)  
  
  // col titles
  const [titleI , setTitleI] = useState('')  
  const [titleII , setTitleII] = useState('')  
  const [titleIII , setTitleIII] = useState('')  
  const [titleIV , setTitleIV] = useState('') 

  
  
  
  useEffect(()=>{
    
    if (props.context === 'home_clean'){
      // state up main image
      setMainImage(hti)

      // state of texts
      setDesc(homeText.desc)
      setblockIText(homeText.bedroom)
      setblockIIText(homeText.kitchen)
      setblockIIIText(homeText.bathroom)
      setblockIVText(homeText.other)

      // state ok colum images
      setVectori(bedroom)
      setVectorii(kitchen)
      setVectoriii(bathroom)
      setVectoriv(other)

      // state ok colum titles
      setTitleI(homeText.bedroomTitle)
      setTitleII(homeText.kitchenTitle)
      setTitleIII(homeText.bathroomTitle)
      setTitleIV(homeText.otherTitle)

      setNotIncluded(homeText.not_included)
    }else if (props.context === 'inout_clean'){
      // state up main image
      setMainImage(moveMain)

      // state of texts
      setDesc(inOutText.desc)
      setblockIText(inOutText.bedroom)
      setblockIIText(inOutText.kitchen)
      setblockIIIText(inOutText.bathroom)
      setblockIVText(inOutText.other)

      // state ok colum images
      setVectori(bedroom)
      setVectorii(kitchen)
      setVectoriii(bathroom)
      setVectoriv(other)

      // state ok colum titles
      setTitleI(inOutText.bedroomTitle)
      setTitleII(inOutText.kitchenTitle)
      setTitleIII(inOutText.bathroomTitle)
      setTitleIV(inOutText.otherTitle)

      setNotIncluded(inOutText.not_included)
    
    }else if (props.context === 'commercial_clean'){
      // state up main image
      setMainImage(commercialMain)

      // state of texts
      setDesc(commercialText.desc)
      setblockIText(commercialText.office)
      setblockIIText(commercialText.bathroom)
      setblockIIIText(commercialText.pantary)
      setblockIVText(null)

      // state ok colum images
      setVectori(officeIcon)
      setVectorii(bathroomIcon)
      setVectoriii(teaIcon)
      setVectoriv(null)

      // state ok colum titles
      setTitleI(commercialText.officeTitle)
      setTitleII(commercialText.bathroomTitle)
      setTitleIII(commercialText.pantryTitle)
      setTitleIV(null)

      setNotIncluded(commercialText.not_included)
    }else if (props.context === 'dis_infection_clean'){
      // state up main image
      setMainImage(infectionMain)

      // state of texts
      setDesc(disInfectionText.desc)
      setblockIText(null)
      setblockIIText(null)
      setblockIIIText(null)
      setblockIVText(null)

      // state ok colum images
      setVectori(null)
      setVectorii(null)
      setVectoriii(null)
      setVectoriv(null)

      // state ok colum titles
      setTitleI(null)
      setTitleII(null)
      setTitleIII(null)
      setTitleIV(null)

      setNotIncluded(disInfectionText.not_included)
    }else if (props.context === 'spring_clean'){

      // state up main image
      setMainImage(springMain)

      // state of texts
      setDesc(springText.desc)
      setblockIText(springText.bedroom)
      setblockIIText(springText.kitchen)
      setblockIIIText(springText.bathroom)
      setblockIVText(springText.other)

      // state ok colum images
      setVectori(bedroom)
      setVectorii(kitchen)
      setVectoriii(bathroom)
      setVectoriv(other)

      // state ok colum titles
      setTitleI(springText.bedroomTitle)
      setTitleII(springText.kitchenTitle)
      setTitleIII(springText.bathroomTitle)
      setTitleIV(springText.otherTitle)

      setNotIncluded(springText.not_included)
      
    }
  },[props])

    
  return (
  <div>
    {/* top main image */}
    <img src={mainImage} className=' mb-3 shadow rounded' style={{maxWidth:'100%'}} />

    {/* description */}
        {desc}

      {/* will only run if column has icon / if there is data in all the columns or atleast one column */}
      {vectori &&   <h4 className='text-center font-style text-warning' > What We Offer?</h4>}

        <div className="row text-center row-cols-2">

      {/* will only run if column1 has icon */}
          {vectori && <div className="col  p-1">
            <div className="shadow-sm  rounded p-1" style={{minHeight:'100%',backgroundColor:'#ff00b31a'}}>
              <h4 className='font-style text-muted' >{titleI}</h4>
              <img src={vectori} className=' pb-3' style={{width: '25%'}} />
              {blockIText}
            </div>
          </div>}

      {/* will only run if column2 has icon */}
          {vectorii && <div className="col  p-1">
            <div className="shadow-sm rounded p-1" style={{minHeight:'100%',backgroundColor:'#ffdd001a'}}>
              <h4 className='font-style text-muted' >{titleII}</h4>
              <img src={vectorii} className=' pb-3' style={{width: '25%'}} />
              {blockIIText}
            </div>
          </div>}

      {/* will only run if column3 has icon */}
          {vectoriii && <div className="col  p-1">
            <div className="shadow-sm rounded p-1" style={{minHeight:'100%',backgroundColor:'#00dbff1a'}}>
              <h4 className='font-style text-muted' >{titleIII}</h4>
              <img src={vectoriii} className=' pb-3' style={{width: '25%'}} />
              {blockIIIText}
            </div>
          </div>}

      {/* will only run if column4 has icon */}
          {vectoriv && <div className="col  p-1" >
            <div className="shadow-sm rounded p-1" style={{minHeight:'100%',backgroundColor:'#00ff191a'}}>
              <h4 className='font-style text-muted' >{titleIV}</h4>
              <img src={vectoriv} className=' pb-3' style={{width: '25%'}} />
              {blockIVText}
            </div>
          </div>}

        </div>
        
        {/* displays the this that are not included in services */}
        <h4 className='text-center font-style text-danger py-1' >Things Not In Clecklist</h4>

        {notIncluded}

  </div>
    
  )
}

export default Service;