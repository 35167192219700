export const homeText ={'title':'REGULAR HOME CLEANING','desc': "Welcome to Universal HRA Home Cleaning Services, where we're dedicated to elevating your living space. Our professional team specializes in regular cleaning to keep your home consistently fresh and tidy. For a deeper transformation, we offer comprehensive deep cleaning services. Moving? Count on us for move in/out cleaning, ensuring a seamless transition. Our solutions are tailored to your needs and budget, granting you the flexibility to choose what matters most.A trained and background-checked team, and a satisfaction guarantee, we're your trusted partner in creating a cleaner, healthier, and more comfortable living environment. Contact us today to book your next cleaning service.",

'bedroomTitle':'BEDROOM',
'bedroom':<p className='font-style ' >
                Vacuum and mop floor 
                <p className='my-0 text-muted' >Clean all mirrors </p>
                Tidy Beds 
                <p className="text-muted">Dusting of furniture</p>
            </p>,

'kitchenTitle':'KITCHEN',
'kitchen': <p className='font-style '>
                Mop floor 
                <p  className='my-0 text-muted'>Wipe cooking stove</p> 
                Wipe exterior electrical appliances 
                <p className='my-0 text-muted' >Wipe exterior surface of all cabinets</p>
                Wash basin 
            </p>,

'bathroomTitle':'BATHROOM',
'bathroom': <p className='font-style ' >
                Clean and disinfect toilet bowl 
                <p className='my-0 text-muted'  >Clean bathtub if any</p> 
                Clean basin and tap
                <p className='my-0 text-muted'  >Wash toilet floor and walls</p>
                Clean all mirrors 
            </p>,

'otherTitle':'ADDITIONAL',
'other': <p className='font-style '>
            Empty trash 
            <p className="text-muted my-0" >Ironing</p>
            <p className="text-muted my-0" >Folding clothes</p>
            <p className="text-muted my-0" >Empty trash</p>  </p>,

'not_included':<div><p className="text-muted" >This task does not encompass the cleaning of ceiling and wall fans, the exterior of windows and grilles in multi-story homes or high-rise apartments, curtain rails, relocating furniture, disposing of renovation debris, dishwashing, laundry, or packing personal belongings.</p><b>While customers are encouraged to supply their own cleaning equipment and solutions, we do offer the option to provide a cleaning kit for a nominal fee if needed.</b></div>
}


export const inOutText ={'title':'Move In/Out CLEANING','desc': "Moving is undoubtedly a hassle and a headache, no matter how good or fast will you handle it. The process of sorting through and packing up years of your life and moving with all of it to a brand new home… That’s heartbreaking to know, how actually exhausting that procedure is. If that already crazy mixture has some additional components added, like young children, simultaneous job issues or any kinds of additional social obligations, and you may go bonkers in no time. Thankfully, our company’s services will allow you to focus just on the most essential and basic organizing issues. So, brace yourself for a profound clean up help, and limit your stress to at least some of extent",

'bedroomTitle':'BEDROOM',
'bedroom':<p className='font-style  py-0' >
                Dust and clean all accessible surfaces 
                <p className='my-0 text-muted' >Vacuum and mop all accessible floors</p>
                Empty visible garbage bins
                <p className='my-0 text-muted'>Mirrors cleaned and polished</p>
                Clean and polish all glass-top surfaces
                <p className='my-0 text-muted' >Clean door handles, light switches and power points</p>
                <p className='my-0' >Cobwebs removed & Dust accessible air vents</p>
                <p className='my-0 text-muted' >Clean exterior of all cupboards and drawers</p>
                Clean interior of all cupboards and drawers
                <p className='my-0 text-muted'>Dust and wipe skirting boards</p>
            </p>,

'kitchenTitle':'KITCHEN',
'kitchen': <p className='font-style '>
                Clean all chairs and tables 
                <p  className='my-0 text-muted'>Clean and degrease stove, hotplates and hood</p> 
                Clean oven exterior 
                <p className='my-0 text-muted' >Clean oven interior</p>
                Clean and polish sink and taps
                <p  className='my-0 text-muted'>Clean exterior fridge, dishwasher / appliances</p> 
                Clean exterior of all cupboards, pantry and drawers
            </p>,

'bathroomTitle':'BATHROOM',
'bathroom': <p className='font-style ' >
                Clean and sanitise shower screens and tiles 
                <p className='my-0 text-muted'>Clean and sanitise toilet</p> 
                Clean and sanitise bath and sink
                <p className='my-0 text-muted'>Clean and polish sink and taps</p>
                Plugholes clean and free of debris
                <p className='my-0 text-muted'>Clean exterior of all cupboards and drawers</p>
            </p>,

'otherTitle':'ADDITIONAL',
'other': <p className='font-style '>
            Empty trash 
            <p className="text-muted">Clean balcony (if any)</p>  </p>,

'not_included':<div><p className="text-muted" >This task does not encompass the cleaning of ceiling and wall fans, the exterior of windows and grilles in multi-story homes or high-rise apartments, curtain rails, relocating furniture, disposing of renovation debris, dishwashing, laundry, or packing personal belongings.</p><b>While customers are encouraged to supply their own cleaning equipment and solutions, we do offer the option to provide a cleaning kit for a nominal fee if needed.</b></div>
}



export const commercialText ={'title':'COMMERCIAL CLEANING','desc': "Ensure your commercial space leaves a lasting, positive impression. A cluttered, unclean workspace not only tarnishes your image but also hampers employee morale and productivity. Invest in professional commercial cleaning services to maintain a pristine environment. We offer tailored solutions, from office cleaning and janitorial services to floor care and window cleaning. Our experienced team ensures a healthy, welcoming atmosphere. With eco-friendly practices and flexible scheduling, we prioritize your specific needs. Don't overlook the impact of a clean workspace; it's a smart long-term investment in your business's success. Contact us today for a customized quote.",

'officeTitle':'OFFICE AREA',
'office':<p className='font-style  py-0' >
                Vacuum all carpeted surfaces
                <p className='my-0 text-muted' >Empty trash</p>
                Dust all tables, ledges, and other office equipment
                <p className='my-0 text-muted'>Clean glass</p>
                Sweep & mop hard surfaces
                <p className='my-0 text-muted' >Perform additional spot cleaning as required</p>
            </p>,

'bathroomTitle':'BATHROOM',
'bathroom': <p className='font-style ' >
                Clean and sanitise shower screens and tiles 
                <p className='my-0 text-muted'>Clean and sanitise toilet</p> 
                Clean and sanitise bath and sink
                <p className='my-0 text-muted'>Clean and polish sink and taps</p>
                Plugholes clean and free of debris
                <p className='my-0 text-muted'>Clean exterior of all cupboards and drawers</p>
                Clean interior of all cupboards and drawers
            </p>,

'pantryTitle':'PANTRY AREA',
'pantary':<p className='font-style '>
            Mop floor
            <p className='my-0 text-muted'>Wipe pantry area</p> 
            Wipe exterior of electrical appliances       
            <p className='my-0 text-muted'>To replenish water and coffee bean</p> 
            Replenish consumables snack, drink..etc  
            <p className='my-0 text-muted'>Wiping of dining table and chair</p> 
            Empty trash
        </p>,

'not_included':<div><p className="text-muted" >This task does not encompass the cleaning of ceiling and wall fans, the exterior of windows and grilles in multi-story homes or high-rise apartments, curtain rails, relocating furniture, disposing of renovation debris, dishwashing, laundry, or packing personal belongings.</p><b>While customers are encouraged to supply their own cleaning equipment and solutions, we do offer the option to provide a cleaning kit for a nominal fee if needed.</b></div>
}



export const disInfectionText ={'title':'DISINFECTION CLEANING','desc': <p> In today's world, maintaining a clean and hygienic environment is paramount. At UNIVERSAL HRA, we specialize in comprehensive disinfection cleaning services designed to safeguard your space against harmful pathogens, viruses, and bacteria.


<div><p className="text-muted" >
    <h3>
    Our Services Include:
    </h3>
    <p className="text-muted">
    <b>Advanced Disinfection Techniques:</b> We use state-of-the-art disinfection equipment and EPA-approved disinfectants to thoroughly sanitize surfaces, providing a safe and healthy environment.
    </p>
    <p className="text-muted">
    <b>Customized Solutions:</b> Every space is unique, and we tailor our disinfection services to your specific needs, whether it's an office, medical facility, school, or any other commercial or residential space.
    </p>
    <b>High-Touch Surface Focus:</b> Our meticulous approach targets high-touch surfaces, such as doorknobs, light switches, and shared equipment, to reduce the risk of contamination.
    <p>
    <b>Flexible Scheduling:</b> We understand the importance of minimal disruption to your routine, and we work around your schedule to provide timely disinfection services.
    </p>
    UNIVERSAL HRA is committed to delivering top-notch disinfection cleaning services to protect your well-being and the well-being of those in your space. Don't compromise on safety and hygiene. Contact us today for a customized disinfection plan and take the first step towards a cleaner, healthier future.
    </p>
</div>
</p>,
'not_included':<div><b>While customers are encouraged to supply their own cleaning equipment and solutions, we do offer the option to provide a cleaning kit for a nominal fee if needed.</b></div>
}



export const springText ={'title':'SPRING CLEANING','desc': "Spring cleaning is an annual tradition that signifies the arrival of warmer, brighter days. It's a time when we roll up our sleeves, throw open the windows, and rejuvenate our homes after a long, cold winter. Our spring cleaning services are designed to help you refresh and revitalize your living spaces, creating a fresh and welcoming environment for the season ahead.",

'bedroomTitle':'BEDROOM',
'bedroom':<p className='font-style  py-0' >
                Dust and clean all accessible surfaces 
                <p className='my-0 text-muted' >Vacuum and mop all accessible floors</p>
                Empty visible garbage bins
                <p className='my-0 text-muted'>Mirrors cleaned and polished</p>
                Clean and polish all glass-top surfaces
                <p className='my-0 text-muted' >Clean door handles, light switches and power points</p>
                <p className='my-0' >Cobwebs removed</p>
                <p className='my-0 text-muted'>Dust and wipe skirting boards</p>
            </p>,

'kitchenTitle':'KITCHEN',
'kitchen': <p className='font-style text-muted'>
                Clean all chairs and tables 
                <p  className='my-0'>Clean and degrease stove, hotplates and hood</p> 
                <p className='my-0' >Clean oven interior</p>
                Clean and polish sink and taps
                <p  className='my-0'>Clean exterior fridge, dishwasher / appliances</p> 
                Clean exterior of all cupboards, pantry and drawers
            </p>,

'bathroomTitle':'BATHROOM',
'bathroom': <p className='font-style text-muted' >
                Clean and sanitise shower screens and tiles 
                <p className='my-0'>Clean and sanitise toilet</p> 
                Clean and sanitise bath and sink
                <p className='my-0'>Clean and polish sink and taps</p>
                Plugholes clean and free of debris
                <p className='my-0'>Clean exterior of all cupboards and drawers</p>
            </p>,

'otherTitle':'ADDITIONAL',
'other': <p className='font-style text-muted'>
            Empty trash 
            <p>Clean balcony (if any)</p>  </p>,

'not_included':<div><p className="text-muted" >This task does not encompass the cleaning of ceiling and wall fans, the exterior of windows and grilles in multi-story homes or high-rise apartments, curtain rails, relocating furniture, disposing of renovation debris, dishwashing, laundry, or packing personal belongings.</p><b>While customers are encouraged to supply their own cleaning equipment and solutions, we do offer the option to provide a cleaning kit for a nominal fee if needed.</b></div>
}

