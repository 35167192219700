import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {NavLink ,Link} from 'react-router-dom'
import {logo} from '../assets/images/Index.js'
import  '../App.css'


export default class Navbar extends Component {


  


  render() {
    return (
      <div>
        <nav   className="navbar rounded navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                <img src={logo} className=''  style={{width:'10rem'}}  /> 
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="nav nav-underline mx-5 py-3">
                    <li className="nav-item mx-1">
                    <NavLink className="nav-NavLink  " to='/' >Home</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink  className="nav-NavLink  " to='/about/' >About</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink className="nav-NavLink   " to="/contact/">Contact</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink className="nav-NavLink " to="/jobs/">Jobs</NavLink>
                    </li>
                   
                </ul>
                </div>
            </div>
            </nav>
      </div>
    )
  }
}
