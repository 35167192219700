import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import {NotificationManager} from 'react-notifications'
import Loading from '../components/Loading'


const LocationFetcher = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);
    // https://38a3-2404-7c80-c-25b0-62eb-69ff-fe95-c5e.ngrok-free.app/#/location-fetcher?FLBLK=JHVSKJBFLBHFK

    // Function to extract the query parameter from URL
    const getParameterFromURL = (paramName) => {
        const params = new URLSearchParams(location.search);
        return params.get(paramName);
    };

    const fetchLocation = () => {
        setLoading(true);

        // Get the current position
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const urlParam = getParameterFromURL('FLBLK');  // fetch location by link key
                    console.log(urlParam)
                    setLat(latitude)
                    setLon(longitude)
                    
                    console.log(position.coords)
                    sendLocationToServer(latitude, longitude, urlParam);
                },
                (error) => {
                    console.error("Error fetching location: ", error);
                    setLoading(false);
                    NotificationManager.error('Failed to fetch location',"",10000)
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
            setLoading(false);
        }
    };

    const sendLocationToServer = (latitude, longitude, param) => {
        fetch(`${process.env.REACT_APP_HOST_API_URL}/app/save-location-from-link/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                latitude: latitude,
                longitude: longitude,
                param: param, // Send parameter from URL
            }),
        })
        .then((response) => {
            // Check if the response is OK (status code in the range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json(); // Wait for the promise to resolve
        })
        .then((data) => {
            console.log('Result:', data.message); 
            if (data.message === 'location updated'){

                NotificationManager.success('Location Updated!', "Success", 10000);
            }else{
                NotificationManager.error('Update Period Expired!', "", 10000);

            }
   
        })
        .catch((error) => {
            console.error("Error saving location:", error);
            NotificationManager.error('Failed to save location', "", 10000);
        })
        .finally(() => {
            setLoading(false); // This will run whether the fetch was successful or not
        });
        
    };


    // fetch initial location 
    useEffect(()=>{
        const fetchInitialLocation = () => {
            setLoading(true);
    
            // Get the current position
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLat(latitude)
                        setLon(longitude)
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching location: ", error);
                        
                        setLoading(false);
                    }
                );
            } else {
               
                setLoading(false);
            }
        };

        fetchInitialLocation()
    },[])





    return (
        <>
        {loading && <Loading/>}
        <div className='' style={{minHeight:'70vh',padding:'20px'}}>
            <Navbar/> 

            <h3 className='text-info mt-3'>We’ve Got You Covered!</h3>
            <p className='text-muted'>Share your location, and our team will be on the way to make your place spotless.</p>
            <p  className='text-muted'>Let’s help you get the cleaning service you deserve, right where you need it!</p>

                <button onClick={fetchLocation} disabled={loading} className='btn btn-md text-light btn-info'>
                    {loading ? 'Fetching Location...' : 'Share my current Location'}
                </button>

            <div style={{ marginTop: '50px', textAlign: 'center' }}>
                <section className="mapouter"><section className="gmap_canvas"><iframe width="100%" height="600" id="gmap_canvas" src={`https://maps.google.com/maps?q=${lat},${lon}&t=&z=15&ie=UTF8&iwloc=&output=embed`}  ></iframe></section></section>
                    {/* z parameter is for zoom */}
            </div>
        </div>
        <Footer/>
        </>
    );
};

export default LocationFetcher;
