import React,{useState} from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import '../assets/css/Jobs.css'
import {jobsMain  } from '../assets/images/Index.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import {NotificationManager} from 'react-notifications'
import Loading from '../components/Loading'
import validator from 'validator';

function Jobs() {

  // for collecting the file
  const[selectedFile, setSelectedFile] = useState(null)

  const [loading , setLoading] = useState(false)
  const [email , setEmail] = useState('')

  // handel on change of input
  const onFileChange =(e)=>{
    // extract the file 
    setSelectedFile(e.target.files[0]);
  }

  const form = {
    outline: 'none',
    background: 'white',
    border: '1px solid #535353',
    borderRadius: '5px',
    color:'#0564bf',
    padding: '5px',
    width:'280px'
  }

  // sending file to server
  const sendFileToServer = async()=>{

    if(selectedFile === null){
      NotificationManager.warning('Please Select File',"",3000)
      return
    }

    const maxSizeInBytes = 2 * 1024 * 1024; //converting byte to mb
    const fileSize = selectedFile.size;

    // check if file size geater than max size
    if(fileSize > maxSizeInBytes){
      NotificationManager.warning('File size should be less than 2MB',"",3000)
      return
    }

    if(!validator.isEmail(email)){
      NotificationManager.warning('Enter Valid Email',"",3000)
      return
    }   

    // creating form data
    const formData = new FormData();

    // adding file and email to form data
    formData.append('file',selectedFile);
    formData.append('email',email);

    try {
      setLoading(true)
      let response = await fetch(`${process.env.REACT_APP_HOST_API_URL}/api/job/`,{
      method:'POST',
      body:formData
    })

    let data = await response.json()
    setLoading(false)
    if (response.status === 200){
      NotificationManager.success('Your Resume is Submited',"Congratulations",10000)
      
    }else{
      NotificationManager.error('Something Went Wrong',"",3000)
    }
    
  }catch(err){
    NotificationManager.error('Something Went Wrong',"",3000)
    }
  }


  return (
    <div className='main'>

    {loading && <Loading/>}

    {/* <div className=""><Navbar/> </div> */}
    <div className='base'  style={{backgroundImage:`url(${jobsMain})`,backgroundPosition:'top'}} >
      <div className='child-block'>
        <Navbar/> 
        <p  className='m-4 font-style text-light'  style={{fontSize:'3rem'}} >Looking For  <br /> <b className='' style={{color:'#0564bf'}}  >Jobs ?</b> </p>                 
      </div>    
    </div>

    <div className="text-center  p-2 text-light mb-3"  style={{backgroundColor:'#25b7fd'}}>
      <h1 className="font-style ">What We Need From You</h1>
    </div>
            <p className='mx-3'>
            <b className='color' >Job Summary:</b><br />
            We are seeking a dedicated and meticulous cleaner to join our team. As a cleaner, you will be responsible for ensuring a clean and organized environment for our clients. Your attention to detail and commitment to cleanliness will be crucial in delivering a top-notch cleaning service. </p>
            <p className='mx-3'>
           <b className='color'> Key Responsibilities:</b><br />

            Perform a variety of cleaning activities such as sweeping, mopping, dusting, and vacuuming.<br />
            Ensure all rooms are cared for and inspected according to standards.<br />
            Protect equipment and make sure there are no inadequacies.<br />
            Notify superiors on any damages, deficits, and disturbances.<br />
            Deal with reasonable complaints/requests with professionalism and patience.<br />
            Check stocking levels of all consumables and replace when appropriate.<br />
            Adhere strictly to rules regarding health and safety and be aware of any company-related practices.<br /> 
            </p>
            <p className='mx-3'>
            <b className="color">Requirements:</b> <br />

            Proven experience as a cleaner or housekeeper is a plus. <br />
            Ability to work with little supervision while maintaining high standards of cleaning. <br />
            Physical stamina and mobility, including the ability to lift, bend, and kneel.<br />
            Excellent time management skills.<br />
            Integrity and ability to handle sensitive information with discretion. <br />
            Good communication skills.<br />
            High school degree or equivalent.<br />
            Willingness to learn and adapt to new cleaning methods and techniques.<br />
            </p>
            <p className="mx-3">
            <b className="color">How to Apply:</b>  <br />
            If you are a hardworking and detail-oriented individual looking to join a team dedicated to providing exceptional cleaning services, we would love to hear from you. Please submit your resume and a brief cover letter outlining your relevant experience
            </p>

            <form action="" >
            <label for="resume" className="mt-3 drop-container" id="dropcontainer">
              <span className="drop-title">Drop Resume here</span>
              or
              <input type="file" id="fileInput" accept='.doc,.docx,.pdf'  onChange={onFileChange}    />
              <input type="email" placeholder='Email' className='text-center' value={email} onChange={(e)=>setEmail(e.target.value)} style={form}  name="email" id="email" />
              <button type="button"  onClick={sendFileToServer}  className=' btn btn-outline-info' >   
              <FontAwesomeIcon icon={faPaperPlane} fade   size='xl' className='mx-4' style={{color: "#0464c0",}} />
              </button>
            </label>
            </form>

            <Footer  />
    </div>
    
  )
}

export default Jobs;