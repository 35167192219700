import React ,{ useState} from 'react'
import Navbar from '../components/Navbar';
import {buildings} from '../assets/images/Index.js'
import '../assets/css/Contact.css'
import Footer from '../components/Footer'
import {cfb,cig,cm,ctt,location,mailbox,phone} from '../assets/images/Index.js'
import validator from 'validator';
import {NotificationManager} from 'react-notifications'
import Loading from '../components/Loading'

function Contact() {

  // for collecting data from submit form
  const [name , setName] = useState('')
  const [email , setEmail] = useState('')
  const [mobile , setMobile] = useState('')
  const [comment , setComment] = useState('')

  // appear loading signal
  const [loading , setLoading] = useState(false)



  const contactSubmit= async(e)=>{
    e.preventDefault()

  //  validating every single  input before submit
    if(name.length <= 3){
        NotificationManager.warning('Enter Valid Name',"",3000)
        console.log(name)
        return 
      }

    if(!validator.isEmail(email)){
      NotificationManager.warning('Enter Valid Email',"",3000)
      console.log(email)
      return
    }
    
    if(mobile.length <= 5){
      NotificationManager.warning('Enter Valid Mobile Number',"",3000)
      console.log(mobile)
      return
    }
    
    if(comment.length <= 10){
      NotificationManager.warning('Minimum length required',"",3000)
      return
    }

    // show loading 
    setLoading(true)

    // sending data to api fro save contact information
    try { let response = await fetch(`${process.env.REACT_APP_HOST_API_URL}/api/contact/`,{
      method:'POST',
      headers:{
        'Content-Type': 'application/json '
      },
      body:JSON.stringify({
        'name':name,
        'email':email,
        'mobile':mobile,
        'comment':comment
      })
    })

    // if status is positive
    if( response.status === 200){
      NotificationManager.success('Thankyou! We will connect you shortely',"Success",10000)
      
    }else{
      NotificationManager.error('Something Went Wrong',"",10000)
    }
    
  }
  
  // catch the error from (try catch) method
  catch(err){
      NotificationManager.error('Something Went Wrong ',"",10000)
    }
    // cancle loading if any error occure in fetching api
  setLoading(false)
  }

  return (
    <div id='contact-root'>
      
           {loading && <Loading/>}

               {/* {--------------Image Section---------------} */}
            <div className='base' style={{backgroundImage:`url(${buildings})`}} >
            <div className='child-block'>
                <Navbar/> 
                <h1 className='mt-4 div-font  text-center'>
                  CONTACT
                </h1>
                <h5 className='text-center text-light' >
                  Reach out to us for inquiries, bookings, or assistance. We're here to serve you.
                </h5>
            </div>    
            </div>


            {/* {--------------contact Left Section---------------} */}

            <div className="row m-0 my-4 p-3 text-center align-items-center ">

              <div className="col-sm-5">
                  <h1 >
                  Keep In <br / > <b className='text-info' >touch</b>
                  </h1>
                  <p className="text-muted">
                  Stay connected with us for updates, special offers, and cleaning tips. Join our Broadcast or follow us on social media.
                  </p>
                  <div className='my-2'>
                  
                    <a  href="https://www.instagram.com/universalhrahome/?igshid=YWYwM2I1ZDdmOQ%3D%3D"  target='_blank' >
                        <img src={cig} className='icon mx-2' /> 
                    </a>

                    <a href="https://www.facebook.com/people/Hra-Cleaning/pfbid02DESZrCn6roEpHkv6Bo6kUfdrUzMTms6thTsM4BLGGQfJmTUbN7UKwyDDwwJozDaol/" target='_blank' >
                        <img src={cfb} className='icon mx-2' />  
                    </a>

                    <a href="https://www.tiktok.com/@hra.cleaning?_t=8gDl3hmv63O&_r=1" target='_blank' >
                        <img src={ctt} className='icon mx-2'   />
                    </a>

                    <a href="mailto:support@Universalhra.com">
                        <img src={cm} className='icon mx-2'   /> 
                    </a>
              
              </div>
              </div>

              {/* {--------------contact Right Section---------------} */}

              <div className="col-sm-7 px-1 form-style ">
                <form action=""className='' onSubmit={contactSubmit}  > 

                  
                  <input type="text" onChange={(e) => setName(e.target.value)} value={name} className='input-style' name="name" id="name" placeholder='Name' />
                  
                  <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className='input-style'  name="email" id="email" placeholder='Email' />

                  <input type="number" onChange={(e) => setMobile(e.target.value)} value={mobile} className='input-style'  name="Phone" id="last_name" placeholder='Phone number with country code' />

                  <input type="text" onChange={(e) => setComment(e.target.value)} value={comment} className='input-style'  name="comment" id="comment" placeholder='What are you looking for?' />

                  <input type="submit" value='Keep in touch' className=' my-2 btn btn-outline-info '  />

                </form>
              </div>

            </div>

            {/* {--------------contact detail Section---------------} */}

            {/* <div className='px-5'>
            <hr className='my-3 text-dark  '/>
            </div> */}

            <div className="row m-0 p-5 text-center contact-block ">
            
            <div className="col-sm" data-aos="fade-up" data-aos-duration="1500">
              <span> 
                <img src={phone} className=' mx-2' style={{width:'3rem'}}  /> 
              </span> 
              <div><span className='h2 font-style text-secondary'>Business Phone</span>
              <p className="text-muted font-style">
                +65 8805 8461
              </p></div>
            </div>


            <div className="col-sm" data-aos="fade-up" data-aos-duration="1500">
              <span> 
                <img src={location} className=' mx-2' style={{width:'4rem'}}  /> 
              </span> 
              <div>
              <span className='h2 font-style text-secondary'>Postal Address</span>
              <p className="text-muted font-style">
              JURONG WEST STREET 64 <br /> BLK 664D    SINGAPORE (644664)
              </p>
              </div>
            </div>


            <div className="col-sm " data-aos="fade-up" data-aos-duration="1500">
              <span> 
                <img src={mailbox} className=' mx-2' style={{width:'4rem'}}  /> 
              </span>
              <div>
              <span className='h2 m-0 font-style text-secondary'>Send Us</span>
              <p className="text-muted">
                Support@universalhra.com
              </p>
              </div>

            </div>
            </div>

            {/* show the map of business office location */}

            <section className="mapouter"><section className="gmap_canvas"><iframe width="100%" height="274" id="gmap_canvas" src="https://maps.google.com/maps?q=JURONG WEST STREET 64 BLK 664D SINGAPORE (644664)&t=&z=13&ie=UTF8&iwloc=&output=embed"  ></iframe></section></section>

            <Footer/>

            </div>   


  )
}

export default Contact;


