import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {fb,ig,mail,tiktok, fullLogo} from '../assets/images/Index.js'
import  '../App.css'

export default class Footer extends Component {
  render() {
    return (
      <div className='pt-3' style={{backgroundColor:'white'}}>

        <div className="row m-0  " >

            <div className="col-sm-4"> 
                <div className="row m-0 align-items-center" >
                    <div className="col-auto mt-2  ">
                        <img src={fullLogo} className=''  style={{width:'7rem'}}  /> 

                    
                    </div>
                    
                    <div className=" col-hide col my-2 border-start border-secondary ">
                        <p className="text-secondary font-style">
                          
                            JURONG WEST STREET 64 <br /> BLK 664D  <br /> SINGAPORE (644664)
                        </p>
                       
                    </div>
                </div>
            </div>
            <div className="col-sm-4 text-center font-style mt-2">
                <div className="row text-muted">
                    <div className="col-6">
                        <p className="text-end my-0">Home Cleaning</p>
                        <p className="text-end my-0 ">Commercial </p>
                        <p className="text-end my-0">Baby Care</p>
                        <p className="text-end my-0">Cooking Helper</p>

                    </div>
                    <div className="col-6">
                        <p className="text-start my-0">Cooking Helper</p>
                        <p className="text-start my-0">Spring Cleanig</p>
                        <p className="text-start my-0">Elder Care</p>
                        <p className="text-start my-0">Move In/Out</p>

                    </div>
                </div>
            </div>

            <div className="col-sm-4 icon-box mt-2" >
            <div >
                <a  href="https://www.instagram.com/universalhrahome/?igshid=YWYwM2I1ZDdmOQ%3D%3D"  target='_blank' >
                    <img src={ig} className='icon mx-2' /> 
                </a>

                <a href="https://www.facebook.com/people/Hra-Cleaning/pfbid02DESZrCn6roEpHkv6Bo6kUfdrUzMTms6thTsM4BLGGQfJmTUbN7UKwyDDwwJozDaol/" target='_blank' >
                    <img src={fb} className='icon mx-2' />  
                </a>

                <a href="https://www.tiktok.com/@hra.cleaning?_t=8gDl3hmv63O&_r=1" target='_blank' >
                    <img src={tiktok} className='icon mx-2'   />
                </a>

                <a href="mailto:support@Universalhra.com">
                    <img src={mail} className='icon mx-2'   /> 
                </a>
                
            </div>
            <p className="footer-text font-style mt-2 text-secondary">
                Support : support@Universalhra.com
            </p>
            <p className="footer-text font-style mt-2 text-secondary">
                UEN : 202323487C
            </p>
           
            </div>

        </div>
        <div className=" text-center pb-2 ">
                    
                    <span className="nav-item px-2 border-end border-secondary">
                    <Link className="nav-link d-inline text-secondary  " to='/' >Home</Link>
                    </span>
                    <span className="nav-item px-2 border-end border-secondary">
                    <Link  className="nav-link d-inline text-secondary " to='/about/' >About</Link>
                    </span>
                    <span className="nav-item px-2 border-end border-secondary">
                    <Link className="nav-link d-inline text-secondary" to="/contact/">Contact</Link>
                    </span>
                    <span className="nav-item px-2 ">
                    <Link className="nav-link d-inline text-secondary" to="/jobs/">Jobs</Link>
                    </span>
                    <p className="d-inline footer-text mx-2 font-style">
                    &copy; 2023 Universal HRA Ltd. All Rights Reserved
                    </p>
   
        </div>

      </div>
    )
  }
}
