import React,{useEffect,useState} from 'react'
import '../App.css';
import Navbar from '../components/Navbar'
import vector1 from '../assets/images/vector1.jpeg'
import person from '../assets/images/person.png'
import thumb from '../assets/images/thumb.png'
import Footer from '../components/Footer'
import Cookies from 'js-cookie'
import {homeText, inOutText , commercialText,
        disInfectionText , springText} from '../Text/TextInOut.js'
import {
  spring,  cooking,
  prise,  office, home, 
  old, baby,infection,img,img2,img1,img3, ctt,cm,cfb,cig,ocean,
  close,  inout, 
} from '../assets/images/Index.js'

import Loading from '../components/Loading'
import Service from './Service';
import validator from 'validator';
import {NotificationManager} from 'react-notifications'



function Home() {
  
  // setup email for subscribe input
  const [email, setEmail] = useState('')

  // check if banner needs to display
  const [showBanner , setShowBanner] = useState(false);

  // banner will only Worker if offer is true
  const [offer , setOffer] = useState(true);

  // help to rerender the user Effect
  const [count , setCount] = useState(0);

  // image url that apperar as an offer banner 
  const [poster, setPoster] = useState('')

  const [loading , setLoading] = useState(false)  

  // to change background 
  const [changeBackground , setChangeBackground] = useState(img)  

// child modal states to procees services
  const [title , setTitle] = useState('')  
  const [contentToDisplay , setContentToDisplay] = useState('')  
 

//  open services description 
  function regularCleaning(e){
    if (e === 'home_clean'){
      setContentToDisplay('home_clean')
      setTitle(homeText.title)
    }else if(e === 'inout_clean'){
      setContentToDisplay('inout_clean')
      setTitle(inOutText.title)
    }else if(e === 'commercial_clean'){
      setContentToDisplay('commercial_clean')
      setTitle(commercialText.title)
    }else if(e === 'dis_infection_clean'){
      setContentToDisplay('dis_infection_clean')
      setTitle(disInfectionText.title)
    }else if(e === 'spring_clean'){
      setContentToDisplay('spring_clean')
      setTitle(springText.title)
    }
    
  }

  // func to close banner
  const  closeBanner = ()=> {
    setShowBanner(false);
  }

  // func to save email for further email notifivation (subscribe)
  const onSubmitHandle = async(e)=>{
    e.preventDefault()

    // validate email
    if(!validator.isEmail(email)){
      NotificationManager.warning('Enter Valid Email',"",3000)
      return
    }
    setLoading(true)

    let response = await fetch(`${process.env.REACT_APP_HOST_API_URL}/api/subscribe/`,{
      method:'POST',
      headers:{
        'Content-Type': 'application/json '
      },
      body:JSON.stringify({
        'email':email,
        'comment':'subscribe'
      })
    })
   
    // turn off loading
    setLoading(false)

    if( response.status === 200){
      NotificationManager.success('Thankyou! Now you will receive our regular updates',"Success",10000)
      // empty the email input after submiting
      setEmail('')
      
    }else{
      NotificationManager.error('Something Went Wrong',"",10000)
    }
  }


  useEffect(()=>{
    // to rerender the useEffect
    setCount(1)

    // first of all it checks is offer true
    // then check banner form key from Cookies, if it is not expired
    // do not display the banner image other wise  the banner will appear
    offer && Cookies.get('banner') ?  setShowBanner(false) :  setShowBanner(true)  

    // to set new Cookie and its expire time after old cookie gets expired
    const bannerTimeout = ()=>{
      setTimeout(()=>{
        const expirationTime = new Date(new Date().getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds
        !Cookies.get('banner') && Cookies.set('banner', 'show',{expires:expirationTime})
      },10000)
    }
    
    
    
    return ()=>{  
      offer && bannerTimeout();
      getConfigration();
      }

  },[offer,poster,count])


  useEffect(()=>{

    // to change the background every 5 seconds
    const interval = setInterval(() => {

    const listImages = [img,img1,img2,img3]

    // pickup random images
    const randomIndex = Math.floor(Math.random() * listImages.length);

    // supersede the background state
    setChangeBackground(listImages[randomIndex]);
    }, 25000); 

    return()=> clearInterval(interval);

  },[changeBackground])
  

  // get configrations files and settings
  let getConfigration = async()=>{
    setLoading(true)
    let response = await fetch(`${process.env.REACT_APP_HOST_API_URL}/api/configrations/`,{
    method:'POST',  
    headers:{
      'Content-Type':'application/json'
    }
    })
    let data = await response.json()
    setLoading(false)

    if (response.status === 200){
      // set poster image
      setPoster(`${process.env.REACT_APP_HOST_API_URL}${data.banner}`)
      // check if offer is true or false set in backend
      setOffer(data.offer)
    }else{
      NotificationManager.error('Something Went Wrong',"",10000)
    }
    
  }


  return (
   
      <div >
            {loading && <Loading/>}


            {/* display banner  */}
            
            {  offer && <div className="banner " style={{display:showBanner && offer ? 'block' : 'none'}}  >
              <span className='  'style={{position:'fixed' ,right:'1rem' ,top:'1rem'}}  >
              <img src={close} onClick={closeBanner} className='' style={{Width:'2rem'}} />
              </span>
              <div className="d-flex d-flex justify-content-center align-items-center" style={{minHeight:'100vh'}}  >
                <img src={poster} className='rounded ' style={{maxWidth:'90vw' , maxHeight:'80vh'}} />
              </div>
            </div>
            }


{/* <!-- Modal --> */}
<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header " style={{borderBottom:'0px'}} >
        <h1 className="modal-title fs-5  font-style  " id="staticBackdropLabel">{title}</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body  py-3">
            {/* display modal box for more information */}
        <Service  context = {contentToDisplay} />
        
      </div>
      
    </div>
  </div>
</div>


          <div className='base'   style={{backgroundImage:`url(${changeBackground})`,backgroundPosition:'top'}} >
            
            <div className='child-block'>
              <Navbar/> 
                <h1 className='mt-4 font-style text-light'>
                  Your Happiness Is Our Priority
                </h1>
                  <p  className=' text-light' >
                  At UNIVERSAL HRA, we're committed to making your home or office sparkle. Our expert team ensure your satisfaction.  <br />
              
                  Discover a cleaner, happier space with us. 
                  </p>
                <a href="https://wa.me/+6588058461?text=Thank you for reaching out to us! We appreciate your interest and will be in touch shortly to assist you">
                <button className='btn  text-success btn-sm btn-light' > 
                  <img width="24" height="24" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp--v1"/>  whatsapp</button>
            
                </a>
            </div>
          </div>

          {/* ----------Social Media Links----------- */}

          <div className='below-block ' >
              <div className='child-below-block rounded shadow  ' >
                <div className="row px-3 rounded py-2">
              
                 
                  <div className="col text-muted rounded  align-self-center quote" >
                  " We Make Cleanliness a Reality for You. <br /> Your Trusted Clean Team, Every Time, Every Place!"
                  </div>
                  <div className='my-2'>
                  
                  <a  href="https://www.instagram.com/universalhrahome/?igshid=YWYwM2I1ZDdmOQ%3D%3D"  target='_blank' >
                      <img src={cig} className=' icon mx-2' /> 
                  </a>

                  <a href="https://www.facebook.com/people/Hra-Cleaning/pfbid02DESZrCn6roEpHkv6Bo6kUfdrUzMTms6thTsM4BLGGQfJmTUbN7UKwyDDwwJozDaol/" target='_blank' >
                      <img src={cfb} className='icon mx-2' />  
                  </a>

                  <a href="https://www.tiktok.com/@hra.cleaning?_t=8gDl3hmv63O&_r=1" target='_blank' >
                      <img src={ctt} className='icon mx-2'   />
                  </a>

                  <a href="mailto:support@Universalhra.com">
                      <img src={cm} className='icon mx-2'   /> 
                  </a>
            
                  </div>
                </div>
              </div>
          </div>

          {/* ----------Vector One----------- */}

          <div className='container my-2 '>
              <div className="row">
                <div className="col-sm text-center"  >
                <img src={vector1} className='vector-size'   /> 
                </div>
                <div className="col-sm   mt-3 vector-present " >
                  <span className='list-i '>  <h1 className='font-style'>Cleaning service <br /> <span  className='text-primary' >at your convenience</span> </h1></span>
                  <p className='text-muted' >
                    Our house cleaning service is designed with your convenience in mind. Schedule cleanings that fit your busy life, and enjoy a spotless, refreshed home without the stress.
                  </p>

                  <li className='list-i' > 
                  <img src={person} className='' style={{width:'2rem'}}  /> Expert cleaning techniques</li>

                  <li className='list-i' >
                    <img src={prise} className='' style={{width:'2rem'}}  /> Affordable pricing</li>

                  <li className='list-i' > 
                    <img src={thumb} className='' style={{width:'2rem'}}  /> Guaranteed results</li>
                </div>
              </div>
          </div>

          {/* ---------- Regialr home cleanig----------- */}
          <div className=' mb-2 overflow-hidden' >

          <h1 className="font-style text-service py-3 text-secondary   text-center ">OUR SERVICES</h1>

          <div className='container  mt-4 '>
              <div className="grid-container text-center " >
              <div className="grid-item text-center " data-aos="zoom-in"  data-aos-duration="1000">
                <img src={home} className=' rounded shadow service-dis '   /> 
                </div>

                <div id='regularCleaning'  className="grid-item   vector-space  mt-5 "  >
                  <span className='list-i font-style  alin' >  <h1>REGULAR HOME<br /> <span  style={{color:'rgb(149 138 46)'}} > CLEANING</span> </h1></span>
                  <p className='text-muted alin px-3' >
                  <h6 className="vc">$ 18/Hour</h6>
                  Our regular home cleaning service ensures a consistently clean and comfortable living environment. Our professional team meticulously cleans and tidies your space, from dusting and vacuuming to sanitizing surfaces. We provide flexible scheduling to fit your routine. Enjoy a spotless home and more free time, knowing our dependable service maintains a fresh and inviting living space for you and your family.   
                  </p>
                  <button type="button" onClick={()=>regularCleaning('home_clean')}  className="  btn btn-sm " style={{backgroundColor:'rgb(149, 138, 46)'}}  data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Know More
                  </button>

                 </div>

               
              </div>
          </div>

          <div className='container   '>
          
              <div className="row text-center " >

                <div className="col-sm   vector-space  mt-5 "  >
                  <span className='list-i font-style  alin' >  <h1>MOVE IN/OUT <br /> <span  style={{color:'rgb(237 218 60)'}} > CLEANING</span> </h1></span>
                  <p className='text-muted alin px-3' >
                  <h6 className="vc">$ 25/Hour</h6>
                  Discover relief from the burdensome cleaning tasks that accompany moving with our Move In/Out Cleaning Service. Designed to alleviate your stress, our comprehensive, customizable solutions ensure your new home is pristine or your old one is left spotless. Our professional team offers flexible scheduling, quality service for a small fee, and a satisfaction guarantee. We're dedicated to making your move as smooth as possible, so you can focus on settling into your new space with peace of mind.  
                  </p>
                  <button type="button" onClick={()=>regularCleaning('inout_clean')}  className="btn btn-sm my-2" style={{backgroundColor:'rgb(149, 138, 46)'}}  data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Know More
                  </button>
                 
                  <button class="btn btn-sm mx-2"  style={{backgroundColor:'#25b7fd'}}  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Prise list</button>

                 </div>

                <div className=' col-sm text-center' data-aos="zoom-in" data-aos-duration="1000" >
                <img src={inout} className=' rounded shadow service-dis '   /> 
                </div>

                
              </div>
               
          </div>

          {/* buttom canva for move out prises */}
          <div class="offcanvas offcanvas-bottom" style={{height:'auto'}} tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title font-style " id="offcanvasBottomLabel" style={{color:'rgb(237 218 60)'}}>MOVE IN/OUT Prize List</h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body small">
            <div className="container">
            <div class="row text-center row-cols-3">
             
              
              <div className="col  p-1">
                <div className="border rounded py-2 px-1" style={{minHeight:'100%'}}>
                  <p className="">Below 500<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 300</b></h5>
                  <p className="text-muted my-0">2 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 501 to 800<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 380</b></h5>
                  <p className="text-muted my-0">2 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 801 to 1000<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 450</b></h5>
                  <p className="text-muted my-0">3 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 1001 to 1200<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 480</b></h5>
                  <p className="text-muted my-0">3 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 1201 to 1400<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 540</b></h5>
                  <p className="text-muted my-0">4 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 1401 to 1600<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 600</b></h5>
                  <p className="text-muted my-0">4 hour</p>
                </div>
              </div>

            

            </div>
            </div>
            <div className="text-center text-primary py-1">
              *All Prises are subjected to prevailling GST
            </div>
            </div>
          </div>


          <div className='container  '>
              <div className="grid-container text-center " >
              <div className="grid-item text-center " data-aos="zoom-in" data-aos-duration="1000">
                <img src={office} className=' rounded shadow service-dis '   /> 
                </div>

                <div className="grid-item   vector-space  mt-5 "  >
                  <span className='list-i font-style  alin' >  <h1>COMMERCIAL<br /> <span  style={{color:'rgb(237 60 60)'}} > CLEANING</span> </h1></span>
                  <p className='text-muted alin px-3' >
                  <h6 className="vc">$ 22/Hour</h6>
                  Discover relief from the burdensome cleaning tasks that accompany moving with our Move In/Out Cleaning Service. Designed to alleviate your stress, our comprehensive, customizable solutions ensure your new home is pristine or your old one is left spotless. Our professional team offers flexible scheduling, quality service for a small fee, and a satisfaction guarantee. We're dedicated to making your move as smooth as possible, so you can focus on settling into your new space with peace of mind.  
                  </p>
                  <button type="button" onClick={()=>regularCleaning('commercial_clean')}  className="btn btn-sm my-2" style={{backgroundColor:'rgb(149, 138, 46)'}}  data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Know More
                  </button>
                  <button class="btn btn-sm mx-2"  style={{backgroundColor:'#25b7fd'}}  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottomC" aria-controls="offcanvasBottom">Prise list</button>

                 </div> 

               
              </div>
          </div>


           {/* buttom canva for commercial prises */}
           <div class="offcanvas offcanvas-bottom" style={{height:'auto'}} tabindex="-1" id="offcanvasBottomC" aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title font-style text-danger " id="offcanvasBottomLabel" >COMMERCIAL SPACES Prize List</h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body small">
            <div className="container">
            <div class="row text-center row-cols-3">
             
              
              <div className="col  p-1">
                <div className="border rounded py-2 px-1" style={{minHeight:'100%'}}>
                  <p className="">Below 500<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 420</b></h5>
                  <p className="text-muted my-0">3 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 501 to 800<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 480</b></h5>
                  <p className="text-muted my-0">4 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 801 to 1000<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 520</b></h5>
                  <p className="text-muted my-0">3.5 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 1001 to 1200<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 580</b></h5>
                  <p className="text-muted my-0">4 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 1201 to 1400<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 620</b></h5>
                  <p className="text-muted my-0">3.5 hour</p>
                </div>
              </div>
              
              <div className="col  p-1">
                <div className="border rounded py-2">
                  <p className="">From 1401 to 1600<span className="text-muted">sqft</span> </p>
                  <h5 className="font-style my-0 text-primary "> <b>$ 690</b></h5>
                  <p className="text-muted my-0">4 hour</p>
                </div>
              </div>

            

            </div>
            </div>
            <div className="text-center text-primary py-1">
              *All Prises are subjected to prevailling GST
            </div>
            </div>
          </div>


          <div className='container mt-2 '>
              <div className="row text-center " >

                <div className="col-sm   vector-space  mt-5 "  >
                  <span className='list-i font-style  alin' >  <h1>DISINFECTION <br /> <span  style={{color:'rgb(95 169 43)'}} > SERVICES</span> </h1></span>
                  <p className='text-muted alin px-3' >
                  <h6 className="vc">$ 20/Hour</h6>
                  Protect your health and well-being with our comprehensive disinfection services. Our experienced team employs state-of-the-art equipment and EPA-approved disinfectants to eradicate harmful viruses, bacteria, and pathogens from your surroundings. We follow rigorous protocols to ensure thorough sanitation, promoting a safe environment for homes and businesses. Count on us to provide peace of mind and safeguard your space against contagious diseases. Let our expert disinfection services be your first line of defense in maintaining a clean and healthy living or working environment.
                  </p>
                  <button type="button" onClick={()=>regularCleaning('dis_infection_clean')}  className="btn btn-sm my-2 " style={{backgroundColor:'rgb(149, 138, 46)'}}  data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Know More
                  </button>
                 </div>

                <div className="col-sm text-center" data-aos="zoom-in" data-aos-duration="1000">
                <img src={infection} className=' rounded shadow service-dis '   /> 
                </div>
              </div>
          </div>


          <div className='container  '>
              <div className="grid-container text-center " >
              <div className="grid-item text-center " data-aos="zoom-in"  >
                <img src={spring} className=' rounded shadow service-dis '   /> 
                </div>

                <div className="grid-item   vector-space  mt-5 "  >
                  <span className='list-i font-style  alin' >  <h1>SPRING<br /> <span  style={{color:'rgb(239 195 19)'}} > CLEAN UP</span> </h1></span>
                  <p className='text-muted alin px-3' >
                  <h6 className="vc">$ 25/Hour</h6>
                  Revitalize your space with our Spring Cleaning service. As the season changes, our dedicated team deep-cleans every nook and cranny, refreshing your home or workplace. We remove built-up dust, grime, and clutter, ensuring a clean and rejuvenated environment. From thorough vacuuming and dusting to organizing and sanitizing, we provide a comprehensive cleaning experience. Let us transform your space and welcome the spring season with a fresh, pristine, and inviting atmosphere.  
                  </p>
                  <button type="button" onClick={()=>regularCleaning('spring_clean')}  className="btn btn-sm my-2 my-2" style={{backgroundColor:'rgb(149, 138, 46)'}}  data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Know More
                  </button>

                 </div>

               
              </div>
          </div>

          <div className="container">
            <h1 className='mt-5   font-style border-danger  border-start px-1  border-5' >Explore More...</h1>

            <div className="row   text-center">
              <div className="col-sm my-3" data-aos-easing="ease-in-sine" >
                <div className="par  " >
                <img src={cooking} className=' rounded-pill    explore-dis '    />
                <h4 className='py-1 font-style text-danger' >COOKING SERVICE</h4>
                <span className="text-muted">
                <h6 className="vc">$ 20/Hour</h6>
                Expert cooking assistance for your culinary needs. Simplify your kitchen tasks.
                </span>
                </div>

              </div>
              <div className="col-sm my-3" data-aos-easing="ease-in-sine" >
                <div className="par  " >
                <img src={baby} className=' rounded-pill    explore-dis '    />
                <h4 className='font-style  text-danger' >BABY CARE</h4>
                <span className="text-muted">
                <h6 className="vc">$ 20/Hour</h6>
                Expert baby care services providing love, safety, and nurturing for your little one's well-being and development.
                </span>
                </div>

              </div>
              <div className="col-sm my-3 "data-aos-easing="ease-in-sine" >
                <div className="par  " >
                <img src={old} className=' rounded-pill    explore-dis '    />
                <h4 className='py-1 font-style text-danger' >ELDER CARE</h4>
                <span className="text-muted">
                <h6 className="vc">$ 20/Hour</h6>
                Compassionate elder care for a dignified, secure, and comfortable life in their golden years.
                </span>
                </div>

              </div>

            </div>
          </div>
              <div className='text-center  py-5  ' style={{backgroundImage:`url(${ocean})`,backgroundSize:'cover',backgroundPosition:'center' }}> 
             <div className="  py-3 container shadow rounded" style={{backgroundColor:'#00000054'}}>
             <p className="text-center text-light p-3 my-0" >
              By entering your email and clicking "Subscribe," you will receive regular updates and newsletters from us. 
              </p>
              <form className="input-group mb-3 justify-content-center" onSubmit={onSubmitHandle} >
                <input type="email" style={{maxWidth:'25rem',background:'transparent'}}  className="form-control text-light"  onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                <input  className="btn  btn-light text-primary" type="submit" id="button-addon2" value='Subscribe'  />
              </form>
             </div>
              </div>
                
                <Footer/>
          </div>
        </div>
  )
}

export default Home



