export const spring = require('./spring.jpg');
export const cooking = require('./cooking.jpg');
export const faceMask = require('./mask.png');
export const prise = require('./prise_tag.png');
export const office = require('./office.jpg');
export const home = require('./home.jpg');
export const old = require('./old.jpg');
export const baby = require('./baby.jpg');
export const infection = require('./infection.jpg');
export const buildings = require('./buildings.jpg');
export const fb = require('./fb.png');
export const cfb = require('./cfb.png');
export const ig = require('./ig.png');
export const cig = require('./cig.png');
export const tiktok = require('./tiktok.png');
export const ctt = require('./ctt.png');
export const mail = require('./mail.png');
export const cm = require('./cm.png');
export const about = require('./about.jpg');
export const mailbox = require('./mailbox.png');
export const phone = require('./phone.png');
export const location = require('./location.png');
export const about_image = require('./about_image.jpg');
export const inverted_commas = require('./inverted-commas.png');
export const jobs = require('./jobs.jpg');
export const jobsMain = require('./jobsMain.jpg');
export const telegram  = require('./telegram.png');
export const close  = require('./close.png');
export const ocean  = require('./ocean.jpg');
export const logo  = require('./logo.png');
export const fullLogo  = require('./fullLogo.png');
export const inout  = require('./inout.jpg');
export const hti  = require('./homeTextImage.jpg');
export const commercialMain  = require('./comercialMain.jpg');
export const moveMain  = require('./moveMain.jpg');
export const infectionMain  = require('./infectionMain.jpg');
export const springMain  = require('./springMain.jpg');
export const bedroom  = require('./bedroom.png');
export const kitchen  = require('./kitchen.png');
export const other  = require('./other.png');
export const bathroom  = require('./bathroom.png');
export const bathroomIcon  = require('./bathroomIcon.png');
export const officeIcon  = require('./officeIcon.png');
export const teaIcon  = require('./teaIcon.png');

export const hygiene  = require('./hygiene.png');
export const timeSave  = require('./timeSave.png');
export const trust  = require('./trust.png');
export const customize  = require('./customize.png');

export const certificate  = require('./certificate.jpg');
export const wsh  = require('./wsh.jpg');
export const nea  = require('./NEA.png');


export const img  = require('./2.jpg');
export const img1  = require('./img2.jpg');
export const img2  = require('./img3.jpg');
export const img3  = require('./img4.jpg');
