import React, { useState,useEffect} from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import {about , about_image , inverted_commas,certificate,nea,wsh} from '../assets/images/Index.js'
import '../assets/css/About.css'
import { format } from 'date-fns';
import Loading from '../components/Loading'
import {NotificationManager} from 'react-notifications'


function About() {

  // empty array fto store feedback data
  const [userFeedback , setUserFeedback] = useState([])

  const [loading , setLoading] = useState(false)

  // to make useEffect run teice
  const [count , setCount] = useState(0)


  useEffect(()=>{
    // update state to call useEffect twice
    setCount(1)
    return(
      ()=>{
        try{getFeedback()}catch(err){
          NotificationManager.error('Something Went Wrong ',"",10000)
        }
      }    )
  },[count])

  // function to fetch Feedback data
  let getFeedback = async()=>{
    setLoading(true)
    let response = await fetch(`${process.env.REACT_APP_HOST_API_URL}/api/feedback/`,{
    method:'POST',  
    headers:{
      'Content-Type':'application/json'
    }
    })
    let data = await response.json()
    setLoading(false)

    if (response.status === 200){
      // updating feedBack data to array
      setUserFeedback(data)
    }else{
      NotificationManager.error('Something Went Wrong While fetching API ',"",10000)
    }
    
  }
  
  return (

      <div>

        {loading && <Loading/>}

        <div className='base'  style={{backgroundImage:`url(${about})`}} >
          <div className='child-block'>
             <Navbar/> 
              <h1 className='mt-5 text-light font-style text-center'style={{fontSize:'4rem'}}  >
                Let me introduce about us;
              </h1>
              <p className="text-light text-center">
              With years of experience, We're reliable, thorough, and committed to exceeding your expectations. Let us transform your space into a clean, refreshing haven."
              </p>
          </div>    
        </div>

        <div className="row m-0 mt-5">

          <div className="col-sm-6 px-4">
          <img src={about_image} className=' mx-2' style={{width:'100%'}}  />
          </div>

          <div className="col-sm-6 mt-4 " >
            <div className=''>
            <p className=" h3 font-style text-secondary">
            Your Trusted Cleaning Partner
            </p>
            <p className="text-muted ">
            At UNIVERSAL HRA HOME SERVICE PRIVATE LIMITED, we are dedicated to transforming your living and working spaces into pristine, welcoming environments. With a passion for cleanliness and a commitment to excellence, we have been serving SINGAPORE and its surrounding areas since 2023.
            </p>
            </div>
          </div>

        </div>

        <hr className='mx-5' />
        <div className='p-3 my-4'>
          <p className=" h3 font-style text-secondary">
          Our Mission
          </p>
          <p className="text-muted">
          Our mission is simple: to make your life easier and healthier by providing top-notch cleaning services. We understand the importance of a clean and organized space, not only for your physical well-being but also for your peace of mind. We strive to exceed your expectations every time, ensuring that you have more time for what matters most to you.
          </p>
        </div>



        {/* certificate */}
        <div  className='px-4 py-3 certificate '>
          {/* <div style={{display:'inline'}}>
            <img src={certificate}  className='border '  alt="certificate" style={{height:"300px"}} />
          </div> */}
          <div style={{alignSelf:'center'}}>
            <p className='text-muted p-3'>
            Our company is bizSAFE certified, a testament to our dedication to workplace safety and health. The bizSAFE program has guided us in enhancing our capabilities in these areas. This certification underscores our commitment to minimizing workplace risks, elevating safety standards, and fostering a culture of safety and health within our organization. We remain steadfast in our commitment to prioritize the well-being of our team as we continue to strive for excellence in all facets of our operations.
            </p>


            <div className="d-flex flex-row justify-content-center">
            <div className='justify-content-center  p-1  d-flex ' style={{backgroundColor:'#FFF0F5'}}>
              <img src={certificate} style={{width:"100px"}} className='rounded ' alt="" />
            </div>
            <div className='justify-content-center  p-1  d-flex mx-3' style={{backgroundColor:'#FFF0F5'}}>
              <img src={nea} style={{width:"100px"}} className='rounded ' alt="" />
              {/* <p className='align-self-center text-muted m-0 mx-3' ><b>NEA230792/3487C/N01</b></p> */}
            </div>
            <div className='justify-content-center  p-1   d-flex ' style={{backgroundColor:'#FFF0F5'}}>
              <img src={wsh} style={{width:"100px"}} className='rounded ' alt="" />
            </div>
            </div>
              <p className='text-end text-muted my-2 mx-3' ><b> Lic.no : NEA230792/3487C/N01</b></p>
          </div>
        </div>



   
        <div className='px-3 my-4'>
          <p className=" h3 font-style text-secondary">
          Customer Satisfaction Guaranteed
          </p>
          <p className="text-muted">
          At UNIVERSAL HRA, we are not satisfied until you are. Our commitment to excellence and customer satisfaction drives everything we do. If you ever have any questions or concerns, our friendly customer support team is here to assist you.
          </p>
        </div>

        <div className="container-flex flex"  >
          {userFeedback.map(feed => ( 
            <div key={feed.date}       className="shadow mb-4 item flex-item  rounded mx-3 p-3 " data-aos="fade-left" data-aos-duration="1500" >
            <div>
            <img src={inverted_commas} className='' style={{width:'2rem'}}   /> 
            <p className="text-muted my-2  " style={{flexGrow:''}}   >
              {feed.content}
              </p>
            </div>

            <div className="row mt-5">
              <div className="col-auto">
                <img src={`${process.env.REACT_APP_HOST_API_URL}`+feed.profile} className='rounded-pill' style={{width:'3rem'}}   /> 
              </div>
              <div className="col">
                <p className='color'  > {feed.name}
                </p>  
              </div>
            </div>
            </div>
          ))}


        </div>
        <Footer/>
      </div>
  )
}

export default About;